import type { ActorRefFrom, AnyActorRef } from "xstate";
import { assign, createMachine, spawn } from "xstate";
import { useSelector } from "@xstate/react";
import type { UserState } from "@fscrypto/domain/user-state";
import { actorSystem } from "~/state/system";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";

import type { Event as EpicEvent } from "./epics";
import { createEpics } from "./epics";
import { Subject } from "rxjs";
import { useEffect } from "react";
import { useCurrentUser } from "~/features/current-user";
import { UserStateSidebarTab } from "node_modules/@fscrypto/domain/src/user-state/user-state";

export type GlobalUserStateEvent = {
  type: "GLOBAL.USER_STATE.UPDATED_THEME";
  payload: { theme: "light" | "dark" };
};

type UserStateRefEvent =
  | {
      type: "USER_STATE.UPDATE_THEME";
      payload: {
        theme: "light" | "dark";
        queryId: string | null;
        user: boolean;
      };
    }
  | {
      type: "USER_STATE.INITIALIZE";
    }
  | {
      type: "USER_STATE.CREATE_ROOM_INSTANCE";
    }
  | {
      type: "USER_STATE.ROOM_UPDATE";
    }
  | {
      type: "USER_STATE.SET_SIDEBAR_TAB";
      payload: UserStateSidebarTab;
    };

export const createUserStateMachine = (userState: UserState, userId?: string) => {
  const actions$ = new Subject<EpicEvent>();
  return createMachine(
    {
      id: "user-state",
      tsTypes: {} as import("./user-state.typegen").Typegen0,
      predictableActionArguments: true,
      schema: {
        context: {} as UserStateMachineContext,
        events: {} as UserStateRefEvent | GlobalEvent | EpicEvent,
      },
      invoke: {
        id: "global-events",
        src: () => eventBus.events$,
      },
      on: {
        "USER_STATE.UPDATE_THEME": {
          actions: ["setTheme", "broadcastSetTheme"],
          target: "saving",
        },
        "GLOBAL.SET_ACTIVE_QUERY": {
          actions: "setActiveQuery",
        },
        "GLOBAL.RECENTLY_OPENED.TOGGLE_OPEN": {
          actions: ["setIsRecentOpen"],
          target: "saving",
          cond: "isUser",
        },
        "GLOBAL.SIDEBAR.TOGGLE_OPEN": {
          actions: ["setIsSidebarOpen"],
          target: "saving",
          cond: "isUser",
        },
        "USER_STATE.SET_SIDEBAR_TAB": {
          actions: ["setSidebarTab"],
          target: "saving",
          cond: "isUser",
        },
      },
      initial: "initializing",
      states: {
        initializing: {
          on: {
            "USER_STATE.INITIALIZE": "idle",
          },
        },
        idle: {
          entry: ["setupEpic"],
        },
        saving: {
          entry: ["saveUpdatesEpic"],
          on: {
            "USER_STATE.EPIC.SAVE_UPDATES_SUCCESS": "idle",
          },
        },
      },

      context: {
        theme: userState?.theme ?? "light",
        activeQueryId: null,
        defaultLiveQueryProvider: userState?.defaultLiveQueryProvider ?? null,
        isRecentOpen: userState?.isRecentOpen ?? false,
        isSidebarOpen: userState?.isSidebarOpen ?? false,
        sidebarTab: userState?.sidebarTab ?? "recents",
        userId,
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        setTheme: assign((context, event) => {
          return {
            theme: event.payload.theme,
          };
        }),
        setIsRecentOpen: assign((context, event) => {
          return {
            isRecentOpen: event.payload,
          };
        }),
        setSidebarTab: assign((context, event) => {
          return {
            sidebarTab: event.payload,
          };
        }),
        setIsSidebarOpen: assign((context, event) => {
          return { isSidebarOpen: event.payload };
        }),
        broadcastSetTheme: (context) => {
          eventBus.send({
            type: "GLOBAL.USER_STATE.UPDATED_THEME",
            payload: { theme: context.theme },
          });
        },
        setActiveQuery: assign((_, event) => {
          return {
            activeQueryId: event.payload,
          };
        }),
        saveUpdatesEpic: (context) => {
          actions$.next({
            type: "USER_STATE.EPIC.SAVE_UPDATES",
            payload: {
              theme: context.theme,
              isRecentOpen: context.isRecentOpen,
              isSidebarOpen: context.isSidebarOpen,
              sidebarTab: context.sidebarTab,
            },
          });
        },
      },
      guards: {
        isUser: () => !!userState,
      },
    },
  );
};

interface UserStateMachineContext {
  theme: "light" | "dark";
  activeQueryId: string | null;
  defaultLiveQueryProvider?: string | null;
  isRecentOpen: boolean;
  isSidebarOpen: boolean;
  epic$?: AnyActorRef;
  userId?: string;
  sidebarTab?: UserStateSidebarTab;
}

export type UserStateRef = ActorRefFrom<ReturnType<typeof createUserStateMachine>>;

export const useUserStateMachine = () => {
  const service = actorSystem?.get<UserStateRef>("userState")!;
  useEffect(() => {
    service.send({ type: "USER_STATE.INITIALIZE" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const theme = useSelector(service, (state) => state.context.theme);
  const isSidebarOpen = useSelector(service, (state) => state.context.isSidebarOpen);
  const isRecentOpen = useSelector(service, (state) => state.context.isRecentOpen);
  const queryId = useSelector(service, (state) => state.context.activeQueryId);
  const { currentUser } = useCurrentUser();
  const sidebarTab = useSelector(service, (state) => state.context.sidebarTab);
  return {
    theme,
    setTheme: (theme: "light" | "dark") =>
      service.send({ type: "USER_STATE.UPDATE_THEME", payload: { theme, queryId, user: !!currentUser } }),
    isSidebarOpen,
    isRecentOpen,
    setIsSidebarOpen: (payload: boolean) => eventBus.send({ type: "GLOBAL.SIDEBAR.TOGGLE_OPEN", payload }),
    sidebarTab,
    setSidebarTab: (sidebarTab: UserStateSidebarTab) =>
      service.send({ type: "USER_STATE.SET_SIDEBAR_TAB", payload: sidebarTab }),
  };
};
