import type { Observable } from "rxjs";
import { from, fromEventPattern, merge } from "rxjs";
import { debounceTime, map, mergeMap, startWith, switchMap } from "rxjs/operators";
import { POST } from "~/async/fetch";
import { ofType } from "~/state/epics";
import { $path } from "remix-routes";
import type { UserState } from "@fscrypto/domain/user-state";
import type { LiveObject, Room } from "@liveblocks/client";
import type { UserStateUpdate } from "@fscrypto/domain/user-state";

export type Event = SaveUpdates | SaveUpdatesSuccess | SubscribeToRoomEvents | RoomUpdate | RoomInit;

type SaveUpdates = {
  type: "USER_STATE.EPIC.SAVE_UPDATES";
  payload: Partial<UserState>;
};
type SaveUpdatesSuccess = {
  type: "USER_STATE.EPIC.SAVE_UPDATES_SUCCESS";
  payload: UserState;
};

type SubscribeToRoomEvents = {
  type: "USER_STATE.EPIC.SUBSCRIBE_TO_ROOM_EVENTS";
  payload: { room: Room<{}, UserStateUpdate, {}, {}> };
};

type RoomUpdate = {
  type: "USER_STATE.EPIC.ROOM_UPDATE";
  payload: LiveObject<UserStateUpdate>;
};
type RoomInit = {
  type: "USER_STATE.EPIC.ROOM_INIT";
  payload: LiveObject<UserStateUpdate>;
};

export const createEpics = (actions$: Observable<Event>): Observable<Event> => {
  return merge(saveUpdatesEpic(actions$), subscribeToRoomEventsEpic(actions$));
};

const saveUpdatesEpic = (action$: Observable<Event>) => {
  return action$.pipe(
    ofType("USER_STATE.EPIC.SAVE_UPDATES"),
    debounceTime(600),
    switchMap(({ payload }) => updateUserState({ ...payload })),
    map((r) => ({ type: "USER_STATE.EPIC.SAVE_UPDATES_SUCCESS", payload: r }) as SaveUpdatesSuccess),
  ) as Observable<Event>;
};

export const updateUserState = async ({ theme, isRecentOpen, isSidebarOpen, sidebarTab }: Partial<UserState>) => {
  return POST<UserState>($path("/api/user-state/update"), { theme, isRecentOpen, isSidebarOpen, sidebarTab });
};

const subscribeToRoomEventsEpic = (action$: Observable<Event>) => {
  return action$.pipe(
    ofType("USER_STATE.EPIC.SUBSCRIBE_TO_ROOM_EVENTS"),
    mergeMap((action) =>
      from(getRoomData(action.payload.room)).pipe(mergeMap((root) => subscribeToRootWithInitialEvent(action, root))),
    ),
  );
};

const getRoomData = async (room: Room<{}, UserStateUpdate, {}, {}>) => {
  const { root } = await room.getStorage();
  return root;
};

const subscribeToRootWithInitialEvent = (
  action: SubscribeToRoomEvents,
  root: LiveObject<UserStateUpdate>,
): Observable<Event> => {
  return fromEventPattern<LiveObject<UserStateUpdate>>(
    (handler) => action.payload.room.subscribe(root, handler),
    (_, unsubscribe) => unsubscribe(),
  ).pipe(
    map((event) => ({ type: "USER_STATE.EPIC.ROOM_UPDATE", payload: event }) as Event),
    startWith({ type: "USER_STATE.EPIC.ROOM_INIT", payload: root } as Event),
  );
};
